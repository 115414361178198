import axios from 'axios';

import { getCurrentUserToken } from './';

export async function updateBrandProfile(userData) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/updateProfile`,
        data: userData,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function getCreatorRegions() {
    const userToken = getCurrentUserToken();

    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/creatorRegionList`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function getCreators(creatorSearchFilterList) {
    const userToken = getCurrentUserToken();

    try {
        const response = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/searchCreatorsForBrand`,
            params: creatorSearchFilterList,
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        });
        return response;
    } catch (err) {
        console.log(err);
        return {
            data: {
                creators: [],
            },
        };
    }
}

export async function getPostToCommentCountsOfBrand() {
    const userToken = getCurrentUserToken();
    try {
        const response = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/getPostToCommentCountsOfBrand`,
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        });
        return response;
    } catch (err) {
        console.log(err);
        return {
            data: {
                commentCountsGrouped: [],
            },
        };
    }
}

export async function getPostsToComment(postType, skip) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/getPostsToComment/${postType}/${skip}`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function getCommentRecommendation(postText, topic, language) {
    const userToken = getCurrentUserToken();
    try {
        const response = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/getCommentRecommendation`,
            data: { postText, topic, language },
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        });
        return response;
    } catch (err) {
        console.log(err);
    }
}

export async function markPostAsIgnored(postId) {
    console.log('postId', postId);
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/markPostAsIgnored`,
        data: { postId },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function markPostAsCommented(postId) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/markPostAsCommented`,
        data: { postId },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function getBrandContacts() {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/contacts`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function getBrandProducts() {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/products`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function acceptMissionFullfilment(missionId) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/campaign/missionFulfilled`,
        data: { missionId: missionId },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function sendProduct(missionId) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/campaign/sendProduct`,
        data: { missionId: missionId },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function resendAndSolveShippingIssue(missionId) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/campaign/solvedShippingIssue`,
        data: { missionId: missionId },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function sendBackForNewProofs(missionId) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/brand/campaign/demandNewProof`,
        data: { missionId: missionId },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function getOnePurchasedCreatorDataWithVoucher(creatorId) {
    const userToken = getCurrentUserToken();
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/payment/purchase-creator-data-with-voucher`,
        data: {
            creatorId,
        },
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
}

export async function getVoucherCount() {
    const userToken = getCurrentUserToken();
    if (userToken === null) {
        return Promise.reject('No user token');
    }

    const result = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/usersAuth/getUser`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });

    return result.data.brand.freeCreatorCounter;
}

export async function getPurchasedCreatorsList() {
    const userToken = getCurrentUserToken();
    if (userToken === null) {
        return Promise.reject('No user token');
    }
    const result = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_WINECONX_API_URL}/basic_brand_user/purchasedcreators`,
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
    const creatorsList = result.data;
    return creatorsList;
}
